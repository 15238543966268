const baseRates = {
  CNY: 29.04,
  RUB: 344.60,
  INR: 339.81,
  ZAR: 74.08,
  BRL: 23.24,
  AED: 14.90,
  BRC: 1
};

export default baseRates;