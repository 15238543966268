// src/Footer.js
import React from 'react';
import './Footer.css';
import { ReactComponent as Logo } from '../assets/brc.svg';

const BRCLogo = () => {
  return <Logo width="50" height="50" />;
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <BRCLogo />
          <p>BRICS RESERVE CURRENCY<br />A Global Digital Asset<br />🌐</p>
        </div>
        <div className="footer-column">
          &nbsp;
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><a href="/#what-is-brc">What Is BRC?</a></li>
              <li><a href="/#cross-border-solution">Cross Border Solution</a></li>
              <li><a href="/#transparency">Transparency</a></li>
              <li><a href="/#partners">Partners</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Resources</h4>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Careers</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <p>©2024 BRICS Reserve Currency. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
